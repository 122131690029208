import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { alertController, loadingController } from "@ionic/vue";
import HostMixin from './HostMixin.js';
import WiseGolf from '@/WiseEcom/mixins/WiseGolf.js';

export default {
	mixins: [ HostMixin, WiseGolf ],
    data: () => ({
        form: {
            username: "",
            password: "",
        },
        forgotPassword: false,
        forgotPasswordSuccess: false,
		submitDisabled: false,
		showPassword: false
    }),
	computed: {
		...mapState({
			user: state => state.user.user,
			settings: state => state.common.settings,
			selectedHost: state => state.common.selectedHost,
		}),
		...mapGetters({activeHost: "common/activeHost"}),
	},
	methods: {
		...mapActions({
			logIn: "user/logIn",
			getDoorAndLocationDataFromServer: "door/getDoorAndLocationDataFromServer",
			validateUserDevice: "user/validateUserDevice"
		}),
		togglePasswordVisibility() {
			this.showPassword = !this.showPassword;
		},
		// Submits login or password reset
		async submitLoginForm() {
			if (this.submitDisabled) return;

			// If no host selected show error
			if(!this.selectedHost) {
				this.addErrorAlert(this.$t(this.settings.noHostSelectedText || 'errors.loginError.noHostSelected'), this.forgotPassword ? this.$t("errors.loginError.forgetTitle") : this.$t("errors.loginError.title"))
				return
			}

			if(this.forgotPassword === true) {
				this.submitForgotPassword()
				return
			}

			// If username or password empty show error
			if(!this.form.username.trim() && !this.form.password.trim()) {
				this.addErrorAlert(this.$t("errors.loginError.userAndPassRequired"), this.$t("errors.loginError.userAndPassRequiredTitle"))
				return
			}

			try {
				await this.loadingOverlay();

				if (this.$route.query.golfpisteToken) {
					this.form.golfpisteToken = this.$route.query.golfpisteToken;
				}

				if (this.$route.query.golfpisteUser) {
					this.form.golfpisteUser = this.$route.query.golfpisteUser;
				}
				
				const response = await this.logIn(this.form)
				//console.log(`%cLogin success: `, 'color: lime; padding: 5px 0', response)

				const deviceValidation = await this.validateUserDevice();
					if (!deviceValidation.data.success) {
						this.$_wiseAlert({
							message: this.$_locale("errors.loginError.deviceIdError"),
							buttons: [
								{
									text: this.$_locale('account.logout'),
									role: 'cancel',
									handler: async () => {
											await this.$store.dispatch('user/logOut')
											window.location.reload()
									},
								},
								{
									text: this.$_locale('login.changeDevice'),
									handler: async () => {
										const result = await this.validateUserDevice(true);
										//console.log(result)
										if (result.data.success === false) {
											this.$_wiseAlert({
												role: 'cancel',
												message: this.$_locale('errors.loginError.deviceIdChangeError'), //Write more verbal error here
												buttons: [
													{
														text: this.$_locale('close'),
														role: 'cancel',
														handler: async () => {
																await this.$store.dispatch('user/logOut')
																window.location.reload()
														},
													},
												]
											})
										}
										else {
											this.$_wiseAlert({
												message: this.$_locale('login.deviceChanged'), //Write more verbal error here
												buttons: [
													{
														text: this.$_locale('continue'),
													}
												]
											})
										}
									}
								},
			
							]
						})		
					}

				if (this.settings.useGymFeatures) {
					try {
						// Get door and location data from server
						await this.getDoorAndLocationDataFromServer()
					} catch (error) {
						this.addErrorAlert(this.$t("errors.loginError.doorDataError"), this.$t("error"))
						console.error(error)
					}
				}

				this.$emit('loginComplete', response)

				// Login success redirect user to route
				if(this.urlToOnSuccess) {
					this.$router.push(this.urlToOnSuccess)
				}


			} catch (error) {
				if(error.errno == 2) {
					this.addErrorAlert(this.$t("errors.loginError.error2"), this.$t("errors.loginError.title"), true)
				} else if(error.errno == 3) {
					this.addErrorAlert(this.$t("errors.loginError.error3"), this.$t("errors.loginError.title"), true)
				} else {
					console.log(error)
					this.addErrorAlert(this.$t("errors.loginError.unknown"), this.$t("errors.loginError.title"))
				}
			}
			
			this.loading.dismiss();
		},

		async submitForgotPassword() {
			// If username field is empty show error
			if(this.form.username === "") {
				this.addErrorAlert(this.$t("errors.loginError.noUsername"), this.$t("errors.loginError.forgetTitle"))
				return
			}

			try {
				// QUESTION How to set locale to send reset email?
				this.submitDisabled = true;
				const { data } = await axios.post(
					`${this.activeHost.baseUrl}?action=forgottenpassword&mode=requestnew&appauth=${this.activeHost.appauth}`,
					JSON.stringify({email: this.form.username}),
					{ headers: { "Content-Type": "application/json" } }
				)
				this.submitDisabled = false

				if(data.success === true) {
					// Show password reset success text
					this.forgotPasswordSuccess = true;
					const alert = await alertController.create({
						header: this.$t("errors.loginForgotSuccessTitle"),
						message: this.$t("errors.loginForgotSuccess"),
						backdropDismiss: false,
						buttons: ["Ok"]
						});
						return alert.present();
				}

				if(data.success === false) {
					this.addErrorAlert(this.$t("errors.loginForgotError.emailNotFound"), this.$t("errors.loginError.forgetTitle"))	
				}

			} catch (error) {
				this.submitDisabled = false
				console.error(error)
				this.addErrorAlert(this.$t("errors.loginForgotError.unknown"), this.$t("errors.loginError.forgetTitle"))
			}
		},

		async loadingOverlay() {
			this.loading = await loadingController.create({
				cssClass: "wise-login-overlay",
				message: this.$t("login.loading"),
			});
			await this.loading.present();
		},

		async addErrorAlert(errorMessage, title = this.$t("error"), forgotPassword = false) {	
			const buttonsConfigOk = ["Ok"]
			const buttonsConfigForgot = ["Ok"/*,{text: 'Unohtuiko salasana?', handler: () => this.forgotPassword = true},*/]

			const alert = await alertController.create({
				header: title,
				message: errorMessage,
				buttons: forgotPassword ? buttonsConfigForgot : buttonsConfigOk
			});
			return alert.present();
		},
	},
}