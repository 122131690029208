import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import { alertController, loadingController } from '@ionic/vue';

export default {
	computed: {
		...mapState({
			fitnessData: (state) => state.gym_common.fitnessData,
			user: (state) => state.user?.user,
			settings: (state) => state.common.settings,
		}),
		...mapGetters({
			canUserConfirm: "gym_common/canUserConfirm"
		}),

	},
	methods: {
		...mapActions({
			getFitnessData: "gym_common/getFitnessData",
			handleFitnessEvent: "gym_common/handleFitnessEvent"
		}),

		//New fitness settings for 2024: 
		async getButtonAction(event) {

			// Check if event enrolling is allowed at all
			if (this.settings?.fitness_eventEnroll?.enabled !== true) {
				// Disable enroll button
				return
			}

			// User is admin, but does not have sufficient access rights --> Let them do normal enroll (admin privilegde)
			if (this.user?.isAdmin?.events && event.hasEventEnrollPermission === false) {
				event.action = "disabled"
				event.class = ""
				event.item = ""
				event.icon = "user-add"
				event.text = this.$t('wiseFitness.attendEvent')
			}

			// Check user has rights to enroll
			if (this.user?.isAdmin?.events === false && event.hasEventEnrollPermission === false) {
				// No right to enroll
				event.action = "disabled"
				event.class = ""
				event.item = ""
				event.icon = "user-add"
				event.text = this.$t('wiseFitness.noRightToEnroll')
				return
			}

			// Check if event is enrollable and not canceled, event enroll window is open
			const isEventEnrollable = this.isEventEnrollable(this.settings, this.fitnessData, event)
			const isEventCancellable = this.isEventCancellable(this.settings, this.user?.personId, event)
			const isEventConfirmableByUser = this.checkIsEventConfirmableByUser(this.settings, this.user?.personId, event)
			const isEventAutoConfirmable = this.checkIsEventAutoConfirmable(this.settings, event)
			const userEventStatus = this.userEventStatus(this.user?.personId, event)
			const multipleEnrollmentsAllowed = this.checkIsMultipleEnrollmentsAllowed(this.user?.personId, event)
			//const canUserConfirm = this.canUserConfirm;


			// Comment for release, use for debugging if necessary
			// console.log(
			// 	"userEventStatus:", userEventStatus,
			// 	"isEventEnrollable:", isEventEnrollable,
			// 	"isEventCancellable:", isEventCancellable,
			// 	"isEventConfirmableByUser:", isEventConfirmableByUser,
			// 	"isEventAutoConfirmable:", isEventAutoConfirmable,
			// 	"userEventStatus:", userEventStatus,
			// 	"canUserConfirm:", canUserConfirm,
			// 	"multipleEnrollmentsAllowed:", multipleEnrollmentsAllowed
			// )

			//Check user enrollment status
			if (isEventEnrollable === true) {

				// Check that user has not already enrolled to this event
				if (userEventStatus === 0) {

					//Check if user can register and confirm, and there is room to enroll
					if (event.totalCapacity === 0 || event.totalAttendeeQuantity < event.totalCapacity && event.hasEventEnrollPermission && isEventAutoConfirmable) {
						event.action = "attendregister",
						event.class = "",
						event.item = "",
						event.icon = "user-add",
						event.text = this.$t('wiseFitness.enrollAndConfirm')

						return
					}

					//Check if user can only register and confirm attendance later
					if (event.totalCapacity === 0 || event.totalAttendeeQuantity < event.totalCapacity && event.hasEventEnrollPermission && !isEventConfirmableByUser) {
						event.action = "attend",
						event.class = "",
						event.item = "",
						event.icon = "user-add",
						event.text = this.$t('wiseFitness.attendEvent')

						return

					}

					//Check if user can register and confirm attendance later, but event is not autoconfirmable AND can already confirm
					//This is same as above and could be done without 'isEventConfirmableByUser' check, but I'll leave it here now for possible tweaking in future
					if(event?.totalCapacity === 0 || event?.totalAttendeeQuantity < event?.totalCapacity && event?.hasEventEnrollPermission && isEventConfirmableByUser) {
						event.action = "attend"
						event.class = ""
						event.item = ""
						event.icon = "user-add"
						event.text = this.$t('wiseFitness.attendEvent')

						return

					}

					//User can register, but event is full -> join queue
					if (event.totalAttendeeQuantity >= event.totalCapacity && event.hasEventEnrollPermission) {
						event.action = "joinqueue",
						event.class = "full",
						event.item = "full",
						event.icon = "group",
						event.text = this.$t('wiseFitness.attendEvent')

						return 

					}

					return 
				}

				//Check if user is enrolled and can confirm
				if (userEventStatus === 1) {
					if (isEventConfirmableByUser) {
						event.action = "register",
						event.class = "attendingButton",
						event.item = "attending",
						event.icon = "check",
						event.text = this.$t('wiseFitness.confirmAttendance')
						return 
					}
					if (multipleEnrollmentsAllowed || this.user?.isAdmin?.events === true) {
						if (event.totalCapacity === 0 || (event.totalAttendeeQuantity < event.totalCapacity && event.hasEventEnrollPermission && isEventAutoConfirmable) || (event.totalAttendeeQuantity < event.totalCapacity && this.user?.isAdmin?.events === true)) {
							event.action = "attendregister",
							event.class = "attendingButton",
							event.item = "attending",
							event.icon = "user-add",
							event.text = this.$t('wiseFitness.enrollAndConfirm')
	
							return
						}
						else if (event.totalCapacity === 0 || event.totalAttendeeQuantity < event.totalCapacity && event.hasEventEnrollPermission && !isEventConfirmableByUser) {
							event.action = "attend",
							event.class = "attendingButton",
							event.item = "attending",
							event.icon = "user-add",
							event.text = this.$t('wiseFitness.attendEvent')
	
							return
						}
						else {
							event.action = "disabled",
							event.class = "",
							event.item = "attending",
							event.icon = "user-add",
							event.text = this.$t('wiseFitness.event.canNotConfirmEvent')
						}
					}
					else if (isEventCancellable) {
						event.action = "cancel",
						event.class = "attendingButton",
						event.item = "attending",
						event.icon = "closed",
						event.text = this.$t('wiseFitness.event.cancelAttendEvent')
						return 
					}
					else {
						event.action = "disabled",
						event.class = "attendingButton",
						event.item = "attending",
						event.icon = "clock",
						event.text = this.$t('wiseFitness.event.canNotConfirmEvent')

						return 
					}
				}

				//Check if user is enrolled and confirmed
				if (userEventStatus === 2) {

					if (isEventCancellable) {
						event.action = "cancel",
						event.class = "registeredButton",
						event.item = "registered",
						event.icon = "closed",
						event.text = this.$t('wiseFitness.event.cancelAttendEvent')

						return 
					}
					else {
						event.action = "disabled",
						event.class = "registeredButton",
						event.item = "registered",
						event.icon = "check",
						event.text = this.$t('wiseFitness.event.registeredAndConfirmed')

						return 
					}
				}

				//Check if user is in queue
				if (userEventStatus === 3) {

					if (isEventCancellable) {
						event.action = "cancel",
						event.class = "queuedButton",
						event.item = "queued",
						event.icon = "closed",
						event.text = this.$t('wiseFitness.event.cancelQueue')

						return 
					}
					else {
						event.action = "disabled",
						event.class = "queuedButton",
						event.item = "queued",
						event.icon = "group",
						event.text = this.$t('wiseFitness.event.queued')

						return 
					}

				}
			}
			// If enrollment not open yet but is open in future
			else if (typeof isEventEnrollable === 'string') {
				event.action = "disabled",
				event.class = "",
				event.icon = "clock",
				event.text = `${this.$t("wiseFitness.event.enrollmentStartsOn")} <br /> ${isEventEnrollable}`

				return
			}

			else if (isEventCancellable && [1, 2].includes(userEventStatus) === true) {
				event.action = "cancel",
				event.class = "canceledButton",
				event.item = "registered",
				event.icon = "closed",
				event.text = this.$t('wiseFitness.event.cancelConfirmedEnrollment')
				return
			}

			else if ([1, 2].includes(userEventStatus) === true) {
				event.action = "disabled",
				event.class = "registeredButton",
				event.item = "registered",
				event.icon = "check",
				event.text = this.$t('wiseFitness.event.cancelConfirmedEnrollment')
				return
			}

			// Event has been cancelled
			else if (event.totalCapacity === -1) {
				event.text = this.$t("wiseFitness.event.enrollmentDisabled"),
				event.action = "disabled",
				event.item = "canceled",
				event.icon = "user-add"
				return
			}

			// User is admin (this is to prevent icon not showing if nothing above is true)
			else if (this.user?.isAdmin?.events) {
				event.action = "disabled",
				event.icon = "user-add",
				event.text = "Admin-ilmoittautuminen"
				return
			}
			// Event has already started or already ended for those events where enrollment until end is allowed
			else {
				event.action = "hide",
				event.class = "",
				event.item = "",
				event.icon = "user-add",
				event.text = this.$t("wiseFitness.event.enrollmentDisabled")
				return
			}
		},

		// Check if event start time is in confirmable minutes and current user is marked as attending, but have not yet been confirmed.
		checkIsEventConfirmableByUser(settings, personId, event) {
			if (this.isEventConfirmable(settings, event) === true) {
				if (this.canUserConfirm) {
					return true
				}
			}
			return false
		},

		checkIsEventAutoConfirmable(settings, event) {
			const untilStartMinutes = moment(event?.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');
			const isAutoConfirmable = untilStartMinutes <= settings.fitness_eventConfirm.autoConfirmWithinMinutes;
			return isAutoConfirmable;
		},

		checkIsMultipleEnrollmentsAllowed(personId, event) {
			const multipleEnrollmentsAllowed = event?.settings.allowMultiEnrollments === "1" || event?.settings.allowMultiEnrollments === 1
			const userHasEnrollmentsLeft = event?.eventAttendees?.find((attendee) => attendee.personId === personId && attendee.type === 1)?.quantity < event?.settings.maxAttendeeQuantity;
			//console.log("Has enrollments left:", multipleEnrollmentsAllowed, userHasEnrollmentsLeft)
			return multipleEnrollmentsAllowed && userHasEnrollmentsLeft
		},

		// Get user status for event
		userEventStatus(personId, event) {
			// 0 = Not in attendance
			// 1 = In attendance but not confirmed
			// 2 = In attendance and confirmed in
			// 3 = In queue waiting for open slot
			const isPersonInAttendance = event?.eventAttendees?.findIndex((attendee) => attendee.personId === personId && attendee.type === 1) > -1;
			const isPersonAttendeeConfirmed = event?.eventAttendees?.findIndex((attendee) => attendee.personId === personId && attendee.type === 1 && attendee.registered) > -1;
			const isPersonInQueue = event?.eventAttendees?.findIndex((attendee) => attendee.personId === personId && attendee.type === 3) > -1;
			return (isPersonInAttendance ? (isPersonAttendeeConfirmed ? 2 : 1) : (isPersonInQueue ? 3 : 0));
		},

		// Return true if enrollment currently open, false if enrollment already over or event has been cancelled, datetime string if enrollment opens in the future.
		isEventEnrollable(settings, fitnessData, event) {
			let untilStartMinutes = moment(event?.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');
			const untilEndMinutes = moment(event?.activityEndTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');

			// WISE-7101
			if (settings.fitness_eventEnroll.stopBeforeMinutes < 0) {
				//console.log("Tapahtumaan voi ilmoittautua alkamisen jälkeen", settings.fitness_eventEnroll.stopBeforeMinutes)
				untilStartMinutes = untilStartMinutes - settings.fitness_eventEnroll.stopBeforeMinutes;
			}

			// console.log("Until Start:",untilStartMinutes)
			// console.log("Until End:",untilEndMinutes)


			// For some categories we check the activityEndTime instead of start.
			const canEnrollUntilEnd = fitnessData?.categories !== false ? fitnessData?.categories?.find(category => category.eventCategoryId === event?.eventCategoryId)?.allowEnrollUntilEnd : false

			// Has eventalready started, or already ended for those events where enrollment until end is allowed
			if ((canEnrollUntilEnd === false && untilStartMinutes < 0) || (canEnrollUntilEnd === true && untilEndMinutes < 0)) return false;
			if (canEnrollUntilEnd === false && untilStartMinutes < settings.fitness_eventEnroll.stopBeforeMinutes) return false;

			// Has event been cancelled
			if (event?.totalCapacity === -1) return false;

			// If enrollment not open yet, show date when it will open.
			if (untilStartMinutes > settings.fitness_eventEnroll.startBeforeMinutes) {
				return moment.parseZone(event?.activityStartTime, 'DD.MM.YYYY HH:mm').subtract(settings.fitness_eventEnroll.startBeforeMinutes, 'minutes').format('DD.MM.YYYY HH:mm');
			}

			// Enrollment open
			return true;
		},

		// Check if user can cancel an enrolled or confirmed event
		isEventCancellable(settings, personId, event) {
			const untilStartMinutes = moment(event?.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes');
			const fitnessStatus = this.userEventStatus(personId, event)
			
			const allowEnrollmentCancelBeforeMinutes = typeof settings.fitness_eventEnroll.allowCancelBeforeMinutes === 'undefined' ? true
			: settings.fitness_eventEnroll.allowCancelBeforeMinutes;

			const allowConfirmationCancelBeforeMinutes = settings.fitness_eventConfirm.allowConfirmCancel && 
			(typeof settings.fitness_eventConfirm.allowCancelBeforeMinutes === 'number')
			? settings.fitness_eventConfirm.allowCancelBeforeMinutes
			: false;

			// fitness_eventEnroll.allowCancelBeforeMinutes being false means cancel not allowed. true means cancel allowed. Number means allowed until less than that many minutes to start.
			// If user is enrolled or confirmed and Allow cancel is false, Or untilStartMinutes is under cancel before minutes

			if ([1, 2, 3].includes(fitnessStatus) === true) {

				if (fitnessStatus === 1 && (allowEnrollmentCancelBeforeMinutes === false || (allowEnrollmentCancelBeforeMinutes !== true && untilStartMinutes <= settings.fitness_eventEnroll.allowCancelBeforeMinutes))) {
					return false      
				}

				if (fitnessStatus === 2 && (allowConfirmationCancelBeforeMinutes === false || untilStartMinutes <= settings.fitness_eventConfirm.allowCancelBeforeMinutes)) {
					return false
				}

				// Can cancel (can also always cancel from queue)
				return true
			}
			// Else, if we are here the user should not be able to cancel. This should not happen, because we check fitnessStatus before calling isEventCancellable
			return false;
		},

		// Users can begin to confirm event attendance startBeforeMinutes before event begins.
		isEventConfirmable(settings, event) {
			if (settings.fitness_eventConfirm.enabled === true) {
				const untilStartMinutes = moment(event?.activityStartTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes')
				const untilEndMinutes = moment(event?.activityEndTime, 'DD.MM.YYYY HH:mm').utcOffset(0, true).diff(moment().utcOffset(0, true), 'minutes')
				return untilStartMinutes <= settings.fitness_eventConfirm.startBeforeMinutes && untilEndMinutes > 0;
			}
			return false
		},

		// Marks attendance to event "YYYY-MM-DD HH:mm:ss or null"
		async markAttendance(activityId, attendeeId, markedAttendance) {
			await this.loadingOverlay();
			try {
				await this.markFitnessAttendance({ activityId, attendeeId, markedAttendance })
				this.loading.dismiss();
			} catch (error) {
				this.loading.dismiss();
				console.error(error)
				this.showAlert(this.$t("error"), error)
			}
		},

		async loadingOverlay() {
			this.loading = await loadingController.create({
				cssClass: "wise-login-overlay",
			});
			await this.loading.present();
		},

		// Handles event "attend, attendregister, cancel, joinqueue"
		async handleEvent($event, action, event) {
			$event.stopImmediatePropagation();

			const status = this.userEventStatus(this.user.personId, event)

			//const status = this.userEventStatus(this.user.personId, event)

			if (action === "attendregister" && !this.canUserConfirm) {
				action = "attend"

				const alert = await alertController.create({
					header: this.$t('wiseFitness.attendEvent'),
					message: event.name + ', <br>' + event?.activityStartTime,
					mode: 'ios',
					buttons: [
						{
							text: this.$_locale('cancel'),
							role: 'cancel'
						},
						{
							text: this.$_locale('confirm'),
							cssClass: 'success',
							handler: async () => {
								try {
									this.loadingOverlay();
									const response = await this.handleFitnessEvent({ action, event })
									this.showToast(this.$t("wiseFitness.event.successOperation"), response.success, "success");
								} catch (error) {
									this.showToast(this.$t("wiseFitness.event.error"), error, "warning")
								} finally {
									this.loading.dismiss()
								}					
							}
						}
					],
					})

					await alert.present();

			} else {
				const alert = await alertController.create({
						header: action === 'cancel' ? this.$t('wiseFitness.event.cancelAttendEvent') : action === 'joinqueue' ? this.$t('wiseFitness.event.joinQueue')
						: status === 0 ? this.$t('wiseFitness.attendEvent')
						: status === 1 ? this.$t('wiseFitness.confirmAttendance')
						: this.$t('wiseFitness.eventInfo'),
						message: event.name + ', <br>' + event?.activityStartTime,
						mode: 'ios',
						buttons: [
							{
								text: this.$_locale('cancel'),
								role: 'cancel'
							},
							{
								text: this.$_locale('confirm'),
								cssClass: 'success',
								handler: async () => {
									try {
										this.loadingOverlay();
										const response = await this.handleFitnessEvent({ action, event })
										this.showToast(this.$t("wiseFitness.event.successOperation"), response.success, "success");
									} catch (error) {
										this.showToast(this.$t("wiseFitness.event.error"), error, "warning")
									} finally {
										this.loading.dismiss()
									}					
								}
							}
						],
					})

					await alert.present();
			}
		}
	},
};
